import React from 'react';
import ForgotPassword from '../../@jumbo/components/Common/authComponents/ForgotPassword';

const ForgotPasswordPage = () => (
  <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>
    <ForgotPassword variant="standard" wrapperVariant="" />;
  </div>
);

export default ForgotPasswordPage;
