import React from 'react';
import SignIn from '../../@jumbo/components/Common/authComponents/SignIn';

const Login = () => (
  <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>
    <SignIn variant="standard" wrapperVariant="" />;
  </div>
);

export default Login;
