import {
  ADD_INVOICE,
  DELETE_BULK_INVOICES,
  DELETE_INVOICE,
  EDIT_INVOICE,
  GET_INVOICES,
  SET_INVOICE_DETAILS,
  FILTER_SEARCH_INVOICES,
  MANUAL_SYNC_INVOICES,
} from '../types/Invoice';

const INIT_STATE = {
  invoices: [],
  searchInvoices: [],
  currentInvoice: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES: {
      return {
        ...state,
        invoices: action.payload,
        searchInvoices: action.payload,
      };
    }
    case SET_INVOICE_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_INVOICE: {
      return {
        ...state,
        invoices: [action.payload, ...state.invoices],
      };
    }
    case EDIT_INVOICE: {
      return {
        ...state,
        invoices: state.invoices.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case MANUAL_SYNC_INVOICES: {
      return {
        ...state,
        currentInvoice: action.payload,
      };
    }
    case FILTER_SEARCH_INVOICES: {
      return {
        ...state,
        invoices: state.searchInvoices.filter(
          invoice =>
            (invoice.tableinvoicetype == null ? '' : invoice.tableinvoicetype)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoiceagentcode == null ? '' : invoice.tableinvoiceagentcode)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicecurrencycode == null ? '' : invoice.tableinvoicecurrencycode)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoiceuserwalletaddress == null ? '' : invoice.tableinvoiceuserwalletaddress)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicewalletaddresses == null ? '' : invoice.tableinvoicewalletaddresses)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoiceindex == null ? '' : invoice.tableinvoiceindex)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoiceuserid == null ? '' : invoice.tableinvoiceuserid)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoiceexternalid == null ? '' : invoice.tableinvoiceexternalid)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicedescription == null ? '' : invoice.tableinvoicedescription)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicetransactionid == null ? '' : invoice.tableinvoicetransactionid)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicetransactionstatuscode == null ? '' : invoice.tableinvoicetransactionstatuscode)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicetransactionstatusdescription == null
              ? ''
              : invoice.tableinvoicetransactionstatusdescription
            )
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicesynctype == null ? '' : invoice.tableinvoicesynctype)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoiceamount == null ? '' : invoice.tableinvoiceamount)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoiceuniquecode == null ? '' : invoice.tableinvoiceuniquecode)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicetotal == null ? '' : invoice.tableinvoicetotal)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicetotalsync == null ? '' : invoice.tableinvoicetotalsync)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicesynctimestamp == null ? '' : invoice.tableinvoicesynctimestamp)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoiceisactive == null ? '' : invoice.tableinvoiceisactive)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicestatuscode == null ? '' : invoice.tableinvoicestatuscode)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (invoice.tableinvoicestatusdescription == null ? '' : invoice.tableinvoicestatusdescription)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()),
        ),
      };
    }

    default:
      return state;
  }
};
