import { UPDATE_USER, DELETE_USER } from '../types/Auth';

const INIT_STATE = {
  userindex: null,
  username: null,
  useremail: null,
  usertoken: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case DELETE_USER: {
      return {
        userindex: null,
        username: null,
        useremail: null,
        usertoken: null,
      };
    }
    default:
      return state;
  }
};
