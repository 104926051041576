import React from 'react';
import { NavLink } from 'react-router-dom';

import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtImage from '../../../../@coremat/CmtImage';

const useStyles = makeStyles(() => ({
  logoStyles: {
    maxWidth: '7rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoStylesSm: {
    maxWidth: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Logo = ({ color, ...props }) => {
  const classes = useStyles();
  const logoUrl = color === 'white' ? '/images/logo-white.png' : '/images/logo.png';
  const logoSymbolUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png';

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage src={logoUrl} alt="logo" className={classes.logoStyles} />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoSymbolUrl} alt="logo" className={classes.logoStylesSm} />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
