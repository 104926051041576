import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRETKEY;

export const generateSignature = payload => {
  try {
    let dataQueryString = '';
    for (const data of Object.entries(payload)) {
      dataQueryString = dataQueryString + data[1];
    }
    return CryptoJS.HmacSHA256(dataQueryString, secretKey).toString(CryptoJS.enc.Hex);
  } catch (error) {
    console.log(('=====> error generate signature: ', error));
  }
};
