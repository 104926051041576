import axios from 'axios';
import { generateSignature } from 'utils/crypto';

const agentcode = process.env.REACT_APP_AGENTCODE;
const secretkey = process.env.REACT_APP_SECRETKEY;

const url = process.env.REACT_APP_BACKEND_API;
const url_listWdTransactions = url + '/api/transaction/usdt/trc20/wd/v01/list';
const url_WdManualSync = url + '/api/sync/usdt/trc20/wd/v01/manual';

export const getTransationsLists = async data => {
  const signature = generateSignature(data);
  try {
    const response = await axios.post(
      url_listWdTransactions,
      { timestamp: data.timestamp },
      { headers: { agentcode, secretkey, signature } },
    );
    if (response.data.status.status) {
      return response.data;
    }
  } catch (error) {
    console.error('======>some think error', error);
  }
};

export const manualWdSync = async data => {
  const signature = generateSignature(data);
  try {
    const response = await axios.post(
      url_WdManualSync,
      {
        invoiceindex: data.invoiceindex,
      },
      { headers: { agentcode, secretkey, signature } },
    );
    if (response.data.status.status) {
      return response.data;
    }
  } catch (error) {
    console.error('======>some think error', error);
  }
};
