import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { emailNotValid, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { addNewTransaction, updateTransaction } from '../../../../redux/actions/WdTransaction';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { createUsers } from 'redux/actions/Users';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
}

const labels = [
  { title: 'Home', slug: 'home' },
  { title: 'Office', slug: 'office' },
  { title: 'Other', slug: 'other' },
];

const splitName = user => {
  if (user) {
    const [fName, mName, lName] = user.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }

  return ['', ''];
};

const AddEditUser = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = null;
  // const currentUser = useSelector(({ usersReducer }) => usersReducer.currentUser);
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profile_pic, setProfile_pic] = useState('');
  const [company, setCompany] = useState('');
  const [designation, setDesignation] = useState('');
  const [phones, setPhones] = useState([{ phone: '', label: 'home' }]);

  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setProfile_pic(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      const [fName, lName] = splitName(currentUser);
      setFirstName(fName);
      setLastName(lName);
      setProfile_pic(currentUser.profile_pic);
      setEmail(currentUser.email);
      setCompany(currentUser.company);
      setDesignation(currentUser.designation);
      setPhones(currentUser.phones);
    }
  }, [currentUser]);

  const onPhoneNoAdd = (number, index) => {
    const updatedList = [...phones];
    updatedList[index].phone = number;
    setPhones(updatedList);
    setPhoneError('');
  };

  const onPhoneRowRemove = index => {
    const updatedList = [...phones];
    updatedList.splice(index, 1);
    setPhones(updatedList);
  };

  const onPhoneRowAdd = () => {
    setPhones(phones.concat({ phone: '', label: 'home' }));
  };

  const onLabelChange = (value, index) => {
    const updatedList = [...phones];
    updatedList[index].label = value;
    setPhones(updatedList);
  };

  const onSubmitClick = e => {
    e.preventDefault();
    if (!firstName) {
      setFirstNameError(requiredMessage);
    } else if (!email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid);
    }
    if (firstName !== '' && role !== '') {
      dispatch(
        createUsers({ username: firstName, role: role, password: firstName }, () => {
          history.go(0);
        }),
      );
    }
  };

  const onUserSave = phoneNumbers => {
    const userDetail = {
      profile_pic,
      name: `${firstName} ${lastName}`,
      email,
      phones: phoneNumbers,
      company,
      designation,
    };

    if (currentUser) {
      dispatch(
        updateTransaction({ ...currentUser, ...userDetail }, () => {
          onCloseDialog();
        }),
      );
    } else {
      dispatch(
        addNewTransaction(userDetail, () => {
          onCloseDialog();
        }),
      );
    }
  };

  const isPhonesMultiple = phones.length > 1;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit User Details' : 'Create New User'}</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={onSubmitClick}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 5 }} className="pointer">
              <input {...getInputProps()} />
              <CmtAvatar size={70} src={profile_pic} />
            </Box>
            <Box mb={{ xs: 3, md: 4 }}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="User Name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value);
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Box>
            <Box sx={{ width: '100%' }} mb={{ xs: 3, md: 4 }}>
              <FormControl style={{ width: '100%', margin: 1 }}>
                <InputLabel id="role">Role</InputLabel>
                <Select labelId="role" label="Role" fullWidth value={role} onChange={e => setRole(e.target.value)}>
                  <MenuItem value={'root'}>ROOT</MenuItem>
                  <MenuItem value={'admin'}>ADMIN</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
