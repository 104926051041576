import { fetchError, fetchStart, fetchSuccess } from './Common';
import dateFormat from 'dateformat';
import { GET_INVOICES } from '../types/Invoice';
import { getInvoicesLists } from 'api/Invoice';

export const getInvoices = (dataFetched, filterOptions, cb) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const data = await getInvoicesLists({
        invoicetype: '',
        userid: dataFetched.userid,
        timestamp: dateFormat(new Date(dataFetched.timestamp), 'yyyy-mm-dd'),
      });
      dispatch(fetchSuccess());
      dispatch({ type: GET_INVOICES, payload: data.result });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};
