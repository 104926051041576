import { fetchError, fetchStart, fetchSuccess } from './Common';
import dateFormat from 'dateformat';
import axios from 'axios';
import {
  SET_TRANSACTION_DETAILS,
  GET_TRANSACTIONS,
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
  EDIT_TRANSACTION,
} from '../types/Transaction';
import { getTransationsLists, manualTuSync } from 'api/UtTransaction';

export const manualSync = (data, cb) => {
  console.log('data yang dikirim: ', data);
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const response = await manualTuSync({
        transaction_id: data.transaction_id,
        invoiceindex: data.invoiceindex,
        totalsync: data.totalsync,
      });
      dispatch(fetchSuccess());
      cb(response);
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const getTransactions = (dataFetched, filterOptions, cb) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const data = await getTransationsLists({
        timestamp: dateFormat(new Date(dataFetched.timestamp), 'yyyy-mm-dd'),
      });
      dispatch(fetchSuccess());
      dispatch({ type: GET_TRANSACTIONS, payload: data.result });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const setCurrentTransaction = user => {
  return dispatch => {
    dispatch({ type: SET_TRANSACTION_DETAILS, payload: user });
  };
};

export const addNewTransaction = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('New user was added successfully.'));
          dispatch({ type: ADD_TRANSACTION, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(fetchError(error.message));
      });
  };
};

export const updateTransaction = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_TRANSACTION, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(fetchError(error.message));
      });
  };
};

export const updateTransactionStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/update-status', data)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('User status was updated successfully.'));
          dispatch({ type: EDIT_TRANSACTION, payload: response.data });
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(fetchError(error.message));
      });
  };
};

export const deleteTransaction = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_TRANSACTION, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(fetchError(error.message));
      });
  };
};
