import axios from 'axios';
import { generateSignature } from 'utils/crypto';

const agentcode = process.env.REACT_APP_AGENTCODE;
const secretkey = process.env.REACT_APP_SECRETKEY;
const url = process.env.REACT_APP_BACKEND_API;

const url_listTuTransactions = url + '/api/transaction/usdt/trc20/tu/v01/list';
const url_TuManualSync = url + '/api/sync/usdt/trc20/tu/v01/manual';

export const getTransationsLists = async data => {
  const signature = generateSignature(data);
  try {
    const response = await axios.post(
      url_listTuTransactions,
      { timestamp: data.timestamp },
      { headers: { agentcode, secretkey, signature } },
    );
    if (response.data.status.status) {
      return response.data;
    }
  } catch (error) {
    console.error('======>some think error', error);
  }
};

export const manualTuSync = async data => {
  const signature = generateSignature(data);
  const response = await axios.post(
    url_TuManualSync,
    {
      transaction_id: data.transaction_id,
      invoiceindex: data.invoiceindex,
      totalsync: data.totalsync,
    },
    { headers: { agentcode, secretkey, signature } },
  );
  if (response.data.status.status) return response.data;

  throw new Error(response.data.status.errormessage);
};
