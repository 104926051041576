import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { emailNotValid, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { addNewTransaction, updateTransaction } from '../../../../redux/actions/WdTransaction';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { manualSync } from 'redux/actions/TuTransaction';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const history = useHistory();
  const currentInvoice = useSelector(({ invoice }) => invoice.currentInvoice);
  const [transaction_id, settransaction_id] = useState('');
  const [invoiceindex, setinvoiceindex] = useState('');
  const [totalsync, settotalsync] = useState(0);

  const [transaction_idError, settransaction_idError] = useState('');
  const [invoiceindexError, setinvoiceindexError] = useState('');
  const [totalsyncError, settotalsyncError] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (transaction_id === '') {
      settransaction_idError('transaction_id is empty');
    }
    if (invoiceindex === '') {
      setinvoiceindexError('invoiceindex is empty');
    }
    if (totalsync < 0) {
      settotalsyncError('totalsync must more than zero');
    }

    if (transaction_id !== '' && invoiceindex !== '' && totalsync >= 0) {
      dispatch(
        manualSync(
          { transaction_id, invoiceindex, totalsync: parseFloat(totalsync) },
          returndata => {
            history.go(0);
            // console.log('returndata:', returndata);
          },
        ),
      );
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentInvoice) {
      settransaction_id('');
      setinvoiceindex(currentInvoice.tableinvoiceindex);
      settotalsync(currentInvoice.tableinvoicetotalsync);
    }
  }, [currentInvoice]);

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>Manual Sync "{invoiceindex}"</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" alignItems="center" width={500}>
            <Box mb={{ xs: 3, md: 4 }} width={'100%'}>
              <TextField
                label={'transaction_id'}
                fullWidth
                value={transaction_id}
                onChange={event => {
                  settransaction_idError('');
                  settransaction_id(event.target.value);
                }}
                variant="outlined"
                helperText={transaction_idError}
                error={transaction_idError != ''}
              />
            </Box>
            <Box mb={{ xs: 3, md: 4 }} width={'100%'}>
              <TextField
                type={'number'}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                label={'totalsync'}
                fullWidth
                variant="outlined"
                value={totalsync}
                onChange={e => {
                  settotalsyncError('');
                  settotalsync(e.target.value);
                }}
                helperText={totalsyncError}
                error={totalsyncError != ''}
                className={classes.input}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
