import React, { useContext } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import { sidebarNavs } from '../menus';
import { getUserCookies } from 'utils/cookies';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { defaultRole } from 'routes';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const { role } = getUserCookies();
  const classes = useStyles();
  let menuItem = sidebarNavs;

  //HIDE WHEN ADMIN IS NOT ROOT
  menuItem = sidebarNavs.filter(menu =>
    (role == null ? '' : role).toLowerCase().includes(defaultRole) ? menu : menu.name !== 'UserAdmin',
  );

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={menuItem} />
    </PerfectScrollbar>
  );
};

export default SideBar;
