import axios from 'axios';
import { generateSignature } from 'utils/crypto';

const agentcode = process.env.REACT_APP_AGENTCODE;
const secretkey = process.env.REACT_APP_SECRETKEY;

const url = process.env.REACT_APP_BACKEND_API;
const urlinvoice = url + '/api/invoice/v01/list_all';

export const getInvoicesLists = async data => {
  const signature = generateSignature(data);
  try {
    const response = await axios.post(
      urlinvoice,
      { userid: data.userid, timestamp: data.timestamp },
      { headers: { agentcode, secretkey, signature } },
    );
    if (response.data.status.status) {
      return response.data;
    }
  } catch (error) {
    console.error('======>some think error', error);
  }
};
