import {
  SET_TRANSACTION_DETAILS,
  GET_TRANSACTIONS,
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
  EDIT_TRANSACTION,
  FILTER_SEARCH_TRANSACTIONS,
} from '../types/Transaction';

const INIT_STATE = {
  transactions: [],
  searchTransactions: [],
  currentTransaction: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload,
        searchTransactions: action.payload,
      };
    }
    case SET_TRANSACTION_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_TRANSACTION: {
      return {
        ...state,
        transactions: [action.payload, ...state.transactions],
      };
    }
    case EDIT_TRANSACTION: {
      return {
        ...state,
        transactions: state.transactions.map(transaction =>
          transaction.id === action.payload.id ? action.payload : transaction,
        ),
      };
    }
    case DELETE_TRANSACTION: {
      return {
        ...state,
        transactions: state.transactions.filter(
          transaction => transaction.tabletrxtransactiontransaction_id !== action.payload,
        ),
      };
    }
    case FILTER_SEARCH_TRANSACTIONS: {
      return {
        ...state,
        transactions: state.searchTransactions.filter(
          transaction =>
            (transaction.tabletrxtransactionid == null ? '' : transaction.tabletrxtransactionid)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactioninvoicetype == null ? '' : transaction.tabletrxtransactioninvoicetype)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionwalletaddress == null ? '' : transaction.tabletrxtransactionwalletaddress)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionagentcode == null ? '' : transaction.tabletrxtransactionagentcode)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactioncurrencycode == null ? '' : transaction.tabletrxtransactioncurrencycode)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactiontransaction_id == null ? '' : transaction.tabletrxtransactiontransaction_id)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactiontoken_infosymbol == null ? '' : transaction.tabletrxtransactiontoken_infosymbol)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactiontoken_infoaddress == null
              ? ''
              : transaction.tabletrxtransactiontoken_infoaddress
            )
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactiontoken_infodecimals == null
              ? ''
              : transaction.tabletrxtransactiontoken_infodecimals
            )
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactiontoken_infoname == null ? '' : transaction.tabletrxtransactiontoken_infoname)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionblock_timestamp == null ? '' : transaction.tabletrxtransactionblock_timestamp)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionfrom == null ? '' : transaction.tabletrxtransactionfrom)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionto == null ? '' : transaction.tabletrxtransactionto)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactiontype == null ? '' : transaction.tabletrxtransactiontype)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionvalue == null ? '' : transaction.tabletrxtransactionvalue)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactioninvoiceindex == null ? '' : transaction.tabletrxtransactioninvoiceindex)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionsynctype == null ? '' : transaction.tabletrxtransactionsynctype)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactiontotalsync == null ? '' : transaction.tabletrxtransactiontotalsync)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionsynctimestamp == null ? '' : transaction.tabletrxtransactionsynctimestamp)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactioninserttimestamp == null ? '' : transaction.tabletrxtransactioninserttimestamp)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tabletrxtransactionupdatetimestamp == null ? '' : transaction.tabletrxtransactionupdatetimestamp)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()),
        ),
      };
    }
    default:
      return state;
  }
};
