import { CURRENT_USER, SETACTIVATE_USER, GET_USER, FILTER_SEARCH_USERS } from '../types/Users';

const INIT_STATE = {
  users: [],
  searchUsers: [],
  currentUser: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER: {
      return {
        ...state,
        users: action.payload,
        searchUsers: action.payload,
      };
    }
    case CURRENT_USER: {
      return {
        ...state,
        users: state.users.map(user => {
          if (user.tableadminindex === action.payload.tableadminindex) {
            user.tableadminisactivate = !Boolean(action.payload.tableadminisactivate);
          }
          return user;
        }),
      };
    }
    case SETACTIVATE_USER: {
      return {
        ...state,
        currentUser: state.users.filter(user => user.tableadminindex === action.payload),
      };
    }
    case FILTER_SEARCH_USERS: {
      return {
        ...state,
        users: state.searchUsers.filter(
          transaction =>
            (transaction.tableadminindex == null ? '' : transaction.tableadminindex)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tableadminname == null ? '' : transaction.tableadminname)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tableadminrole == null ? '' : transaction.tableadminrole)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tableadminisactivate == null ? '' : transaction.tableadminisactivate)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tableadmincreatedat == null ? '' : transaction.tableadmincreatedat)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tableadmincreatedby == null ? '' : transaction.tableadmincreatedby)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tableadminupdatedat == null ? '' : transaction.tableadminupdatedat)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()) ||
            (transaction.tableadminupdatedby == null ? '' : transaction.tableadminupdatedby)
              .toString()
              .toLowerCase()
              .includes(action.payload.toLowerCase()),
        ),
      };
    }
    default:
      return state;
  }
};
