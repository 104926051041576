import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Transaction from './Transaction';
import Invoice from './Invoice';
import Users from './Users';

export default history =>
  combineReducers({
    transaction: Transaction,
    invoice: Invoice,
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    users: Users,
  });
