import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '../../../../@jumbo/utils/dateHelper';
import {
  Block,
  CheckCircleOutline,
  Delete,
  Edit,
  Mail,
  MoreHoriz,
  Visibility,
} from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { updateTransactionStatus } from '../../../../redux/actions/WdTransaction';
import { MANUAL_SYNC_INVOICES } from 'redux/types/Invoice';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    { action: 'edit', label: 'Edit', icon: <Edit /> },
    { action: 'email', label: 'Email', icon: <Mail /> },
  ];

  if (user.status === 'active') {
    actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: 'Reactivate',
      icon: <CheckCircleOutline />,
    });
  }

  actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};

const UserListRow = ({
  row,
  isSelected,
  onUserEdit,
  onUserDelete,
  onUserView,
  onTuManualSync,
  onDwManualSync,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'suspend') {
      dispatch(updateTransactionStatus({ id: row.id, status: 'suspended' }));
    } else if (menu.action === 'activate') {
      dispatch(updateTransactionStatus({ id: row.id, status: 'active' }));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  const isItemSelected = isSelected(row.id);
  const userActions = getUserActions(row);

  const handleManualTuSync = row => {
    dispatch({ type: MANUAL_SYNC_INVOICES, payload: row });
    onTuManualSync(true);
  };

  const handleManualDwSync = row => {
    dispatch({ type: MANUAL_SYNC_INVOICES, payload: row });
    onDwManualSync(true);
  };

  return (
    <TableRow
      hover
      // onClick={event => onRowClick(event, row.tabletrxtransactioninvoiceindex)}
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      <TableCell>{row.tableinvoicetype}</TableCell>
      <TableCell>{row.tableinvoiceagentcode}</TableCell>
      <TableCell>{row.tableinvoicecurrencycode}</TableCell>
      <TableCell>{row.tableinvoiceuserwalletaddress}</TableCell>
      <TableCell>{row.tableinvoicewalletaddresses}</TableCell>
      <TableCell>{row.tableinvoiceindex}</TableCell>
      <TableCell>{row.tableinvoiceuserid}</TableCell>
      <TableCell>{row.tableinvoiceexternalid}</TableCell>
      <TableCell>{row.tableinvoicedescription}</TableCell>
      <TableCell>{row.tableinvoicetransactionid}</TableCell>
      <TableCell align="center">{row.tableinvoicetransactionstatuscode}</TableCell>
      <TableCell>{row.tableinvoicetransactionstatusdescription}</TableCell>
      <TableCell>{row.tableinvoicesynctype}</TableCell>
      <TableCell>{row.tableinvoiceamount}</TableCell>
      <TableCell align="center">{row.tableinvoiceuniquecode}</TableCell>
      <TableCell align="center">{row.tableinvoicetotal}</TableCell>
      <TableCell align="center">{row.tableinvoicetotalsync}</TableCell>
      <TableCell>{new Date(row.tableinvoicesynctimestamp).toLocaleString()}</TableCell>
      <TableCell align="center">{row.tableinvoiceisactive}</TableCell>
      <TableCell align="center">{row.tableinvoicestatuscode}</TableCell>
      <TableCell align="center">{row.tableinvoicestatusdescription}</TableCell>
      <TableCell>
        <Button
          color={'primary'}
          variant="outlined"
          onClick={() =>
            row.tableinvoicetype === 'TU' ? handleManualTuSync(row) : handleManualDwSync(row)
          }
          disabled={row.tableinvoicestatusdescription != 'UNPAID'}>
          ManualSync
        </Button>
      </TableCell>
      <TableCell>{new Date(row.tableinvoiceinserttimestamp).toLocaleString()}</TableCell>
      <TableCell>{new Date(row.tableinvoiceexpiretimestamp).toLocaleString()}</TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
