import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '../../../../@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { updateTransactionStatus } from '../../../../redux/actions/WdTransaction';
import { changeToCurrencies } from 'utils/currency';
import { setActivateUsers } from 'redux/actions/Users';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    { action: 'edit', label: 'Edit', icon: <Edit /> },
    { action: 'email', label: 'Email', icon: <Mail /> },
  ];

  if (user.status === 'active') {
    actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: 'Reactivate',
      icon: <CheckCircleOutline />,
    });
  }

  actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView }) => {
  const classes = useStyles();
  const [rowstate, setRowstate] = useState(row);
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'suspend') {
      dispatch(updateTransactionStatus({ id: row.id, status: 'suspended' }));
    } else if (menu.action === 'activate') {
      dispatch(updateTransactionStatus({ id: row.id, status: 'active' }));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  const handleSetActivationUser = row => {
    dispatch(
      setActivateUsers(row, () => {
        setRowstate(prev => ({ ...prev, tableadminisactivate: !rowstate.tableadminisactivate }));
      }),
    );
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      // onClick={event => onRowClick(event, row.tabletrxtransactioninvoiceindex)}
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      <TableCell>{rowstate.tableadminindex}</TableCell>
      <TableCell>{rowstate.tableadminname}</TableCell>
      <TableCell>{rowstate.tableadminrole}</TableCell>
      <TableCell>
        <Button
          color={Boolean(rowstate.tableadminisactivate) ? 'primary' : 'secondary'}
          onClick={() => handleSetActivationUser(row)}>
          {Boolean(rowstate.tableadminisactivate) ? 'active' : 'not active'}
        </Button>
      </TableCell>
      <TableCell>{rowstate.tableadmincreatedby}</TableCell>
      <TableCell>{new Date(rowstate.tableadmincreatedat).toLocaleString()}</TableCell>
      <TableCell>{rowstate.tableadminupdatedby}</TableCell>
      <TableCell>{new Date(rowstate.tableadminupdatedat).toLocaleString()}</TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
