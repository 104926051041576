import React from 'react';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ArrowForward from '@material-ui/icons/ArrowForward';

const customTransaction = {
  name: 'Transactions',
  icon: <AssessmentIcon />,
  type: 'collapse',
  children: [
    {
      name: 'TU Transaction',
      icon: <ArrowForward />,
      type: 'item',
      link: '/tutransactions',
    },
    {
      name: 'WD Transaction',
      icon: <ArrowForward />,
      type: 'item',
      link: '/wdtransactions',
    },
  ],
};

const customInvoice = {
  name: 'Invoice',
  icon: <CollectionsBookmarkIcon />,
  type: 'collapse',
  children: [
    {
      name: 'Invoice',
      icon: <ArrowForward />,
      type: 'item',
      link: '/invoices',
    },
  ],
};

const customUserAdmin = {
  name: 'UserAdmin',
  icon: <SupervisorAccountIcon />,
  type: 'collapse',
  children: [
    {
      name: 'User Admin',
      icon: <ArrowForward />,
      type: 'item',
      link: '/useradmin',
    },
  ],
};

export const sidebarNavs = [customTransaction, customInvoice, customUserAdmin];
