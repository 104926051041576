import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';

const headCells = [
  {
    id: 'tableinvoicetype',
    numeric: false,
    disablePadding: false,
    label: 'type',
  },
  {
    id: 'tableinvoiceagentcode',
    numeric: false,
    disablePadding: false,
    label: 'agentcode',
  },
  {
    id: 'tableinvoicecurrencycode',
    numeric: false,
    disablePadding: false,
    label: 'currencycode',
  },
  {
    id: 'tableinvoiceuserwalletaddress',
    numeric: false,
    disablePadding: false,
    label: 'userwalletaddress',
  },
  {
    id: 'tableinvoicewalletaddresses',
    numeric: false,
    disablePadding: false,
    label: 'walletaddresses',
  },
  {
    id: 'tableinvoiceindex',
    numeric: false,
    disablePadding: false,
    label: 'index',
  },
  {
    id: 'tableinvoiceuserid',
    numeric: false,
    disablePadding: false,
    label: 'userid',
  },
  {
    id: 'tableinvoiceexternalid',
    numeric: false,
    disablePadding: false,
    label: 'externalid',
  },
  {
    id: 'tableinvoicedescription',
    numeric: false,
    disablePadding: false,
    label: 'description',
  },
  {
    id: 'tableinvoicetransactionid',
    numeric: false,
    disablePadding: false,
    label: 'transactionid',
  },
  {
    id: 'tableinvoicetransactionstatuscode',
    numeric: false,
    disablePadding: false,
    label: 'transactionstatuscode',
  },
  {
    id: 'tableinvoicetransactionstatusdescription',
    numeric: false,
    disablePadding: false,
    label: 'transactionstatusdescription',
  },
  {
    id: 'tableinvoicesynctype',
    numeric: false,
    disablePadding: false,
    label: 'synctype',
  },
  {
    id: 'tableinvoiceamount',
    numeric: false,
    disablePadding: false,
    label: 'amount',
  },
  {
    id: 'tableinvoiceuniquecode',
    numeric: false,
    disablePadding: false,
    label: 'uniquecode',
  },
  {
    id: 'tableinvoicetotal',
    numeric: false,
    disablePadding: false,
    label: 'total',
  },
  {
    id: 'tableinvoicetotalsync',
    numeric: false,
    disablePadding: false,
    label: 'totalsync',
  },

  {
    id: 'tableinvoicesynctimestamp',
    numeric: false,
    disablePadding: false,
    label: 'synctimestamp',
  },
  {
    id: 'tableinvoiceisactive',
    numeric: false,
    disablePadding: false,
    label: 'isactive',
  },
  {
    id: 'tableinvoicestatuscode',
    numeric: false,
    disablePadding: false,
    label: 'statuscode',
  },

  {
    id: 'tableinvoicestatusdescription',
    numeric: false,
    disablePadding: false,
    label: 'tstatusdescription',
  },
  {
    id: 'fn_manualsync',
    numeric: false,
    disablePadding: false,
    label: 'manual sync',
  },
  {
    id: 'tableinvoiceinserttimestamp',
    numeric: false,
    disablePadding: false,
    label: 'inserttimestamp',
  },
  {
    id: 'tableinvoiceexpiretimestamp',
    numeric: false,
    disablePadding: false,
    label: 'expiretimestamp',
  },
];

function UserTableHead({ classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell align="center">Actions</TableCell> */}
      </TableRow>
    </TableHead>
  );
}

UserTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default React.memo(UserTableHead);
