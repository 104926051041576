import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '../../../../@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { updateTransactionStatus } from '../../../../redux/actions/WdTransaction';
import { changeToCurrencies } from 'utils/currency';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    { action: 'edit', label: 'Edit', icon: <Edit /> },
    { action: 'email', label: 'Email', icon: <Mail /> },
  ];

  if (user.status === 'active') {
    actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: 'Reactivate',
      icon: <CheckCircleOutline />,
    });
  }

  actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'suspend') {
      dispatch(updateTransactionStatus({ id: row.id, status: 'suspended' }));
    } else if (menu.action === 'activate') {
      dispatch(updateTransactionStatus({ id: row.id, status: 'active' }));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      // onClick={event => onRowClick(event, row.tabletrxtransactioninvoiceindex)}
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      <TableCell>{row.tabletrxtransactiontoken_infosymbol}</TableCell>
      <TableCell>{row.tabletrxtransactiontoken_infoaddress}</TableCell>
      <TableCell>{row.tabletrxtransactiontoken_infodecimals}</TableCell>
      <TableCell>{row.tabletrxtransactiontoken_infoname}</TableCell>
      <TableCell>{new Date(row.tabletrxtransactioninserttimestamp).toLocaleString()}</TableCell>
      <TableCell>{row.tabletrxtransactionfrom}</TableCell>
      <TableCell>{row.tabletrxtransactionto}</TableCell>
      <TableCell>{row.tabletrxtransactiontype}</TableCell>
      <TableCell>{changeToCurrencies(row.tabletrxtransactionvalue, '$')}</TableCell>
      <TableCell>{row.tabletrxtransactioninvoiceindex}</TableCell>
      <TableCell>{row.tabletrxtransactionsynctype}</TableCell>
      <TableCell>{row.tabletrxtransactiontotalsync}</TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
