import Cookies from 'universal-cookie';
const cookies = new Cookies();

const now = new Date();
const fiveHour = 1000 * 60 * 60 * 5;
const theAge = now.getTime() + fiveHour;

export const setUserCookies = ({ usertoken, ...res }) => {
  cookies.set('datauseradmin', res, { path: '/', expires: new Date(theAge) });
  cookies.set('usertoken', usertoken, { path: '/', expires: new Date(theAge) });
};

export const cleanCookies = () => {
  cookies.remove('datauseradmin');
  cookies.remove('usertoken');
};

export const getUserCookies = () => {
  let datauseradmin = cookies.get('datauseradmin') || '{}';
  let usertoken = cookies.get('usertoken') || '';
  return {
    ...datauseradmin,
    usertoken,
  };
};
