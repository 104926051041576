import axios from 'axios';

const url = process.env.REACT_APP_BACKEND_SERVER;

export const signin = async data => {
  try {
    const response = await axios.post(url + '/api/admin/signin', { username: data.username, password: data.password });
    return response.data;
  } catch (error) {
    console.log('error===>', error);
    throw new Error(error);
  }
};

export const forgotpassword = async data => {
  try {
    const response = await axios.post(url + '/api/admin/forgotpassword', { username: data.username });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const logout = async data => {
  try {
    const response = await axios.post(url + '/api/admin/logout', { token: data.token });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
