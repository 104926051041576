import { fetchError, fetchStart, fetchSuccess } from './Common';
import { UPDATE_USER, DELETE_USER } from '../types/Auth';
import { signin, forgotpassword, logout as apiLogout } from 'api/Auth';
import { cleanCookies, getUserCookies, setUserCookies } from 'utils/cookies';

export const login = (data, cb) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const response = await signin({
        username: data.username,
        password: data.password,
      });
      dispatch(fetchSuccess());
      if (response.status.status === 0) {
        dispatch(fetchError(response.status.errormessage));
        return;
      }
      setUserCookies(response.result);
      dispatch({ type: UPDATE_USER, payload: response });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const logout = cb => {
  return async dispatch => {
    const { usertoken } = getUserCookies();
    dispatch(fetchStart());
    try {
      apiLogout({ token: usertoken });
      cleanCookies();
      dispatch(fetchSuccess());
      dispatch({ type: DELETE_USER });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError('There was something issue in responding server.'));
    }
  };
};

export const requestforgotpassword = data => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const response = await forgotpassword({ username: data.username });
      console.log('========> data: ', response);
      dispatch(fetchSuccess());
    } catch (error) {
      console.error(error);
      dispatch(fetchError('There was something issue in responding server.'));
    }
  };
};
