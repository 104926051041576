import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import WdTransactions from './Pages/WdTransactions';
import { getUserCookies } from 'utils/cookies';
import Users from './Pages/Users';
import Invoices from './Pages/Invoices';
import AppLayout from '@jumbo/components/AppLayout';
import TuTransactions from './Pages/TuTransactions';

export const defaultRole = 'root';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { usertoken } = getUserCookies();
  const ProtectedComponent = props => <Component {...props} />;

  if (!usertoken)
    return <Route {...rest} render={props => <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />} />;

  if (usertoken) return <Route {...rest} render={props => <ProtectedComponent {...props} />} />;
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { usertoken, role } = getUserCookies();

  const ProtectedComponent = props => <Component {...props} />;

  if (!usertoken)
    return <Route {...rest} render={props => <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />} />;
  if (role.toLowerCase().includes(defaultRole)) {
    return <Route {...rest} render={props => <ProtectedComponent {...props} />} />;
  }

  return <Route {...rest} render={props => <Redirect to={{ pathname: '/', state: { from: props.location } }} />} />;
};

const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute exact path="/tutransactions" component={TuTransactions} />
        <RestrictedRoute exact path="/wdtransactions" component={WdTransactions} />
        <RestrictedRoute exact path="/invoices" component={Invoices} />
        <RestrictedRoute exact path="/dashboard" component={WdTransactions} />
        <RestrictedRoute exact path="/" component={WdTransactions} />
        <Route exact path="/signin" component={Login} />
        <Route exact path="/signup" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <ProtectedRoute exact path="/useradmin" component={Users} />
        <Route path="*" component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
