import { fetchError, fetchStart, fetchSuccess } from './Common';
import { GET_USER, FILTER_SEARCH_USERS, SETACTIVATE_USER } from '../types/Users';
import { listUsers, setActivation, create } from 'api/Users';
import { getUserCookies } from 'utils/cookies';

export const getUsers = (filterOptions, cb) => {
  const { usertoken } = getUserCookies();
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const data = await listUsers({ token: usertoken });
      dispatch(fetchSuccess());
      dispatch({ type: GET_USER, payload: data.result });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const editUsers = (dataFetched, filterOptions, cb) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const data = await listUsers();
      dispatch(fetchSuccess());
      dispatch({ type: GET_USER, payload: data.result });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const createUsers = (dataFetched, cb) => {
  const { usertoken } = getUserCookies();
  return async dispatch => {
    dispatch(fetchStart());
    try {
      await create({
        username: dataFetched.username,
        role: dataFetched.role,
        password: dataFetched.password,
        token: usertoken,
      });
      dispatch(fetchSuccess());
      // dispatch({ type: GET_USER, payload: data.result });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const setActivateUsers = (dataFetched, cb) => {
  return async dispatch => {
    const { usertoken } = getUserCookies();
    dispatch(fetchStart());
    try {
      const response = await setActivation({
        token: usertoken,
        userindex: dataFetched.tableadminindex,
        setactivate: !Boolean(dataFetched.tableadminisactivate),
      });
      console.log('response: ', response);
      dispatch(fetchSuccess());
      dispatch({ type: SETACTIVATE_USER, payload: dataFetched });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const unActivateUsers = (dataFetched, filterOptions, cb) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const data = await listUsers();
      dispatch(fetchSuccess());
      dispatch({ type: GET_USER, payload: data.result });
      cb();
    } catch (error) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const searchUsers = (searchTerm, cb) => {
  return async dispatch => {
    dispatch({ type: FILTER_SEARCH_USERS, payload: searchTerm });
  };
};
