import axios from 'axios';

const url = process.env.REACT_APP_BACKEND_SERVER;

export const listUsers = async data => {
  try {
    const response = await axios.post(url + '/api/admin/', {
      token: data.token,
    });
    return response.data;
  } catch (error) {
    console.error('error===>', error);
    throw new Error(error);
  }
};

export const setActivation = async data => {
  try {
    const response = await axios.post(url + '/api/admin/setactivation', {
      token: data.token,
      userindex: data.userindex,
      setactivate: data.setactivate,
    });
    return response.data;
  } catch (error) {
    console.error('error===>', error);
    throw new Error(error);
  }
};

export const create = async data => {
  try {
    const response = await axios.post(url + '/api/admin/create', {
      username: data.username,
      role: data.role,
      password: data.password,
      token: data.token,
    });
    return response.data;
  } catch (error) {
    console.error('error===>', error);
    throw new Error(error);
  }
};
