import React from 'react';
import SignUp from '../../@jumbo/components/Common/authComponents/SignUp';

const Register = () => (
  <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>
    <SignUp variant="standard" wrapperVariant="" />;
  </div>
);

export default Register;
